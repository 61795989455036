//i18next framework for translation to different languages throughout the whole app
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

//English language files
import analyticsEN from "./locales/en/analytics/analytics.json";
import settingsEN from "./locales/en/settings/settings.json";
import userSettingsEN from "./locales/en/settings/userSettings.json";
import navigationEN from "./locales/en/navigation/sideMenu.json";
import realtimeMapEN from "./locales/en/realtimeMap/realtimeMap.json";
import riskProfileEN from "./locales/en/riskProfile/riskProfile.json";
import sensorsEN from "./locales/en/sensorManager/sensors.json";
import sensorManagerEN from "./locales/en/sensorManager/sensorManager.json";
import userManagementEN from "./locales/en/userManagement/userManagement.json";
import userProfilePopUpEN from "./locales/en/userManagement/userProfilePopUp.json";
import webViewerEN from "./locales/en/webViewer/webViewer.json";
import loginEN from "./locales/en/settings/login.json";
import headerEN from "./locales/en/navigation/header.json";
import aboutEN from "./locales/en/settings/about.json";
import legendEN from "./locales/en/realtimeMap/legend.json";
import modalEN from "./locales/en/settings/modals.json";
import catchmentItemEN from "./locales/en/catchmentItem/catchmentItem.json";
import gflChartEN from "./locales/en/gflChart/gflChart.json";
import sensorItemEN from "./locales/en/sensorItem/sensorItem.json";
import paginationEN from "./locales/en/settings/pagination.json";
import addToHomeScreenEN from "./locales/en/banner/addToHomeScreen.json";
import addUserEN from "./locales/en/userManagement/addUser.json";
import defaultScreenEN from "./locales/en/defaultScreen.json";
import menu_en from "./locales/en/menu.json";

//German language files
import analyticsDE from "./locales/de/analytics/analytics.json";
import settingsDE from "./locales/de/settings/settings.json";
import userSettingsDE from "./locales/de/settings/userSettings.json";
import navigationDE from "./locales/de/navigation/sideMenu.json";
import realtimeMapDE from "./locales/de/realtimeMap/realtimeMap.json";
import riskProfileDE from "./locales/de/riskProfile/riskProfile.json";
import sensorsDE from "./locales/de/sensorManager/sensors.json";
import sensorManagerDE from "./locales/de/sensorManager/sensorManager.json";
import userManagementDE from "./locales/de/userManagement/userManagement.json";
import userProfilePopUpDE from "./locales/de/userManagement/userProfilePopUp.json";
import webViewerDE from "./locales/de/webViewer/webViewer.json";
import loginDE from "./locales/de/settings/login.json";
import headerDE from "./locales/de/navigation/header.json";
import aboutDE from "./locales/de/settings/about.json";
import legendDE from "./locales/de/realtimeMap/legend.json";
import modalDE from "./locales/de/settings/modals.json";
import catchmentItemDE from "./locales/de/catchmentItem/catchmentItem.json";
import gflChartDE from "./locales/de/gflChart/gflChart.json";
import sensorItemDE from "./locales/de/sensorItem/sensorItem.json";
import paginationDE from "./locales/de/settings/pagination.json";
import addToHomeScreenDE from "./locales/de/banner/addToHomeScreen.json";
import addUserDE from "./locales/de/userManagement/addUser.json";
import defaultScreenDE from "./locales/de/defaultScreen.json";
import menu_de from "./locales/de/menu.json";

//French language files
import analyticsFR from "./locales/fr/analytics/analytics.json";
import settingsFR from "./locales/fr/settings/settings.json";
import userSettingsFR from "./locales/fr/settings/userSettings.json";
import navigationFR from "./locales/fr/navigation/sideMenu.json";
import realtimeMapFR from "./locales/fr/realtimeMap/realtimeMap.json";
import riskProfileFR from "./locales/fr/riskProfile/riskProfile.json";
import sensorsFR from "./locales/fr/sensorManager/sensors.json";
import sensorManagerFR from "./locales/fr/sensorManager/sensorManager.json";
import userManagementFR from "./locales/fr/userManagement/userManagement.json";
import userProfilePopUpFR from "./locales/fr/userManagement/userProfilePopUp.json";
import webViewerFR from "./locales/fr/webViewer/webViewer.json";
import loginFR from "./locales/fr/settings/login.json";
import headerFR from "./locales/fr/navigation/header.json";
import aboutFR from "./locales/fr/settings/about.json";
import legendFR from "./locales/fr/realtimeMap/legend.json";
import modalFR from "./locales/fr/settings/modals.json";
import catchmentItemFR from "./locales/fr/catchmentItem/catchmentItem.json";
import gflChartFR from "./locales/fr/gflChart/gflChart.json";
import sensorItemFR from "./locales/fr/sensorItem/sensorItem.json";
import paginationFR from "./locales/fr/settings/pagination.json";
import addToHomeScreenFR from "./locales/fr/banner/addToHomeScreen.json";
import addUserFR from "./locales/fr/userManagement/addUser.json";
import defaultScreenFR from "./locales/fr/defaultScreen.json";
import menu_fr from "./locales/fr/menu.json";

//Italian language files
import analyticsIT from "./locales/it/analytics/analytics.json";
import settingsIT from "./locales/it/settings/settings.json";
import userSettingsIT from "./locales/it/settings/userSettings.json";
import navigationIT from "./locales/it/navigation/sideMenu.json";
import realtimeMapIT from "./locales/it/realtimeMap/realtimeMap.json";
import riskProfileIT from "./locales/it/riskProfile/riskProfile.json";
import sensorsIT from "./locales/it/sensorManager/sensors.json";
import sensorManagerIT from "./locales/it/sensorManager/sensorManager.json";
import userManagementIT from "./locales/it/userManagement/userManagement.json";
import userProfilePopUpIT from "./locales/it/userManagement/userProfilePopUp.json";
import webViewerIT from "./locales/it/webViewer/webViewer.json";
import loginIT from "./locales/it/settings/login.json";
import headerIT from "./locales/it/navigation/header.json";
import aboutIT from "./locales/it/settings/about.json";
import legendIT from "./locales/it/realtimeMap/legend.json";
import modalIT from "./locales/it/settings/modals.json";
import catchmentItemIT from "./locales/it/catchmentItem/catchmentItem.json";
import gflChartIT from "./locales/it/gflChart/gflChart.json";
import sensorItemIT from "./locales/it/sensorItem/sensorItem.json";
import paginationIT from "./locales/it/settings/pagination.json";
import addToHomeScreenIT from "./locales/it/banner/addToHomeScreen.json";
import addUserIT from "./locales/it/userManagement/addUser.json";
import defaultScreenIT from "./locales/it/defaultScreen.json";
import menu_it from "./locales/it/menu.json";
import webPushModal_de from "./locales/de/settings/webPushModal.json";
import webPushModal_en from "./locales/en/settings/webPushModal.json";
import webPushModal_fr from "./locales/fr/settings/webPushModal.json";
import webPushModal_it from "./locales/it/settings/webPushModal.json";

//page not found
import pageNotFoundEN from "./locales/en/pageNotFound/pageNotFound.json";
import pageNotFoundDE from "./locales/de/pageNotFound/pageNotFound.json";
import pageNotFoundFR from "./locales/fr/pageNotFound/pageNotFound.json";
import pageNotFoundIT from "./locales/it/pageNotFound/pageNotFound.json";

//assignment of the language files (from above) to names that are available in the different classes
const resources = {
  en: {
    analytics: analyticsEN,
    navigation: navigationEN,
    realtimeMap: realtimeMapEN,
    legend: legendEN,
    riskProfile: riskProfileEN,
    sensors: sensorsEN,
    sensorManager: sensorManagerEN,
    settings: settingsEN,
    about: aboutEN,
    userSettings: userSettingsEN,
    userManagement: userManagementEN,
    userManagementPopUp: userProfilePopUpEN,
    webViewer: webViewerEN,
    login: loginEN,
    header: headerEN,
    modal: modalEN,
    catchmentItem: catchmentItemEN,
    gflChart: gflChartEN,
    sensorItem: sensorItemEN,
    pagination: paginationEN,
    addToHomeScreen: addToHomeScreenEN,
    addUser: addUserEN,
    defaultScreen: defaultScreenEN,
    menu: menu_en,
    webPushModal: webPushModal_en,
    pageNotFound: pageNotFoundEN,
  },
  de: {
    analytics: analyticsDE,
    navigation: navigationDE,
    realtimeMap: realtimeMapDE,
    legend: legendDE,
    riskProfile: riskProfileDE,
    sensors: sensorsDE,
    sensorManager: sensorManagerDE,
    settings: settingsDE,
    about: aboutDE,
    userSettings: userSettingsDE,
    userManagement: userManagementDE,
    userManagementPopUp: userProfilePopUpDE,
    webViewer: webViewerDE,
    login: loginDE,
    header: headerDE,
    modal: modalDE,
    catchmentItem: catchmentItemDE,
    gflChart: gflChartDE,
    sensorItem: sensorItemDE,
    pagination: paginationDE,
    addToHomeScreen: addToHomeScreenDE,
    addUser: addUserDE,
    defaultScreen: defaultScreenDE,
    menu: menu_de,
    webPushModal: webPushModal_de,
    pageNotFound: pageNotFoundDE,
  },
  fr: {
    analytics: analyticsFR,
    navigation: navigationFR,
    realtimeMap: realtimeMapFR,
    legend: legendFR,
    riskProfile: riskProfileFR,
    sensors: sensorsFR,
    sensorManager: sensorManagerFR,
    settings: settingsFR,
    about: aboutFR,
    userSettings: userSettingsFR,
    userManagement: userManagementFR,
    userManagementPopUp: userProfilePopUpFR,
    webViewer: webViewerFR,
    login: loginFR,
    header: headerFR,
    modal: modalFR,
    catchmentItem: catchmentItemFR,
    gflChart: gflChartFR,
    sensorItem: sensorItemFR,
    pagination: paginationFR,
    addToHomeScreen: addToHomeScreenFR,
    addUser: addUserFR,
    defaultScreen: defaultScreenFR,
    menu: menu_fr,
    webPushModal: webPushModal_fr,
    pageNotFound: pageNotFoundFR,
  },
  it: {
    analytics: analyticsIT,
    navigation: navigationIT,
    realtimeMap: realtimeMapIT,
    legend: legendIT,
    riskProfile: riskProfileIT,
    sensors: sensorsIT,
    sensorManager: sensorManagerIT,
    settings: settingsIT,
    about: aboutIT,
    userSettings: userSettingsIT,
    userManagement: userManagementIT,
    userManagementPopUp: userProfilePopUpIT,
    webViewer: webViewerIT,
    login: loginIT,
    header: headerIT,
    modal: modalIT,
    catchmentItem: catchmentItemIT,
    gflChart: gflChartIT,
    sensorItem: sensorItemIT,
    pagination: paginationIT,
    addToHomeScreen: addToHomeScreenIT,
    addUser: addUserIT,
    defaultScreen: defaultScreenIT,
    menu: menu_it,
    webPushModal: webPushModal_it,
    pageNotFound: pageNotFoundIT,
  },
};

i18n
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  // want your translations to be loaded from a professional CDN? => https://github.com/locize/react-tutorial#step-2---use-the-locize-cdn
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    resources,
    fallbackLng: "de",
    whitelist: ["en", "de", "fr", "it"],
    debug: true,
    interpolation: {
      // not needed for react as it escapes by default
      escapeValue: false,
    },
    react: {
      useSuspense: false,
      wait: false,
    },
  });

export default i18n;
